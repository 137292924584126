.TabImg {
  width: 85px;
}
.DivWidth.blog {
  width: 35% !important;
}
.CKEditTors {
  font-family: "Roboto", sans-serif;
}
.CKEditTors label {
  margin-bottom: 10px;
  color: #6f6b80;
}
.SHortsForm label {
  color: #6f6b80;
}
.CateGorTab table tr td:nth-child(5) {
  border-left: none;
}
.nail.blog.thumbs {
  padding-top: 39px;
  margin-bottom: 45px;
}
.upLoadedimg {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  width: 280px;
  height: 211px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .DivWidth.blog {
    width: 32% !important;
  }
  .nail.blog.thumbs {
    display: flex;
    padding-top: 39px;
    margin-bottom: 60px;
    justify-content: center;
    align-items: center;
  }
  .SHortsForm {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .nail.blog.thumbs {
    display: flex;
    padding-top: 39px;
    margin-bottom: 45px;
    justify-content: center;
    align-items: center;
  }
  .SHortsForm {
    padding: 0px;
  }
  .hidden-list.blgLi {
    margin-left: -30px;
    margin-top: 4px;
  }
}
.preImage {
  height: 100% !important;
}
