.DivWidth.banner {
  width: 780px !important;
}
.DivWidth.banner:hover {
  color: #107b38;
  cursor: pointer;
}
.ThumBnail.banner {
  margin-left: 0;
  width: 100%;
}
.ThumBnail.banner .upLoad {
  margin: 0;
  width: 100%;
}
.ThumBnail.banner .upLoad .uploadIcon {
  margin: auto;
}

.SHortsForm.banner {
  padding: 0;
}
.professionbanner {
  margin-left: 9px;
  color: #6f6b80;
  font-size: 21px;
}
.bannerinput {
  height: 18px;
}
.custom-file-uploaded {
  display: flex;
  /* flex-direction: row; */
  /* margin: 0px; */
  /* width: 200px; */
  /* height: 70px; */
  margin: 0 auto;
  align-items: center;
}

.uploadIcons {
  position: relative;
  top: 69px;
  font-size: 2rem;
  left: -76px;
  bottom: 75px;
  cursor: pointer;
  height: 42px;
}
.ThumBnail.banner .upLoad p {
  top: 144px;
  left: 394px;
  position: absolute;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .DivWidth.banner {
    width: 415px !important;
  }
  .ThumBnail.banner.subbnr .upLoad p {
    left: 225px;
    top: 104px;
  }
  .uploadIcons.subBnr {
    top: 26px;
    left: -62px;
  }
  .WHt-Btn {
    font-size: 17px !important;
  }
  .Grn-Btn {
    font-size: 17px !important;
  }
}

.upLoaded {
  display: flex;
  flex-direction: row;
  height: 182px;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .ThumBnail.banner {
    margin-top: 0px;
  }
  .ThumBnail.banner .upLoad p {
    left: 185px;
    top: 103px;
  }
  .professionbanner {
    font-size: 18px;
  }
  .uploadIcons {
    top: 25px;
  }
}
