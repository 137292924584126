.GrnActn {
  background: #c7f7da;
  border: 1px solid #107b38;
  padding: 5px;
  width: 100px;
  text-align: center;
  border-radius: 4px;
}
.upLoad-learner {
  display: block;
  margin: 28px 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  width: 229px;
  height: 181px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}
.Grn-Btnmanin.flLef.pass {
  margin: auto;
  width: 100%;
  padding-right: 411px;
}
.RedDel {
  background: #f8d9d9;
  border: 1px solid #ab0a0a;
  padding: 5px;
  width: 100px;
  text-align: center;
  border-radius: 4px;
}

.searchBtNs {
  background: #eeebf6;
  padding: 10px;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 4px;
}

.BtNSSearcH select {
  width: 100%;
  height: 42px;
  background: #fff;
  border: 1px solid #b8b7c1;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
}

.searchBtNs .Grn-Btn {
  margin-top: -8px;
}
.searchBtNs input {
  border: 1px solid #b8b7c1;
  border-right: none;
  font-family: "Roboto", sans-serif;
}

.searchBtNs #button-addon2 {
  background: #fff;
}
.BtNSSearcH a {
  text-decoration: none;
  padding: 8px 20px;
}
.ExpertSBacKG {
  background: #eeebf6;
  padding: 35px;
  font-family: "Roboto", sans-serif;
}
.Grn-Btnmanin.flLef {
  margin: auto;
  width: 100%;
}
.ViewCon {
  padding: 25px;
  padding-top: 0;
  text-align: left;
  width: 50%;
  margin-left: 26px;
}
.ViewCon label {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-align: left !important;
}
.ViewCon select {
  background: #fff;
  border: 1px solid #b8b7c1;
  width: 100%;
  height: 50px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}
.CateGorTab table tr td:nth-child(7) {
  border-left: none;
}
.BtNSSearcH.send-mail a {
  padding: 12px 8px;
}
.ThumBnail.lrnrs {
  margin-left: -3%;
  margin-top: 0px;
}
@media (min-width: 1201px) and (max-width: 1400px) {
  .ViewCon {
    width: 75%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .BtNSSearcH a {
    text-decoration: none;
    padding: 13px 3px;
    font-size: 16px;
  }
  .ThumBnail.lrnrs {
    margin-left: -3%;
    margin-top: -4px;
  }
  .ViewCon {
    width: 75%;
  }
  .Grn-Btnmanin.lrnedit button {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .BtNSSearcH a {
    padding: 8px 15px;
  }
  .ThumBnail.lrnrs {
    margin-left: -3%;
    margin-top: -60px;
  }
  .ViewCon {
    width: 100%;
    padding: 0px;
    margin-top: -25px;
  }
  .Grn-Btnmanin.lrnedit button {
    width: 100%;
    font-size: 12px !important;
    padding: 0px;
  }
  .lrnImg {
    display: flex;
    justify-content: center;
  }
}
.Grn-Btnmanin.lrnedit button {
  width: 100%;
}
