.upLoadthunbnail {
  display: block;
  margin: 38px 78px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  /* padding: 108px 17px; */
  width: 100%;
 height: 100%; 
  position: relative;
  text-align: center;
  color: #b8b7c1;
  margin: 0;
}
.uploadIconsvideo {
  position: relative;
  top: 31px;
  font-size: 2rem;
  left: -137px;
  cursor: pointer;
  height: 42px;

}
.uploadIconsvid {
  position: relative;
  top: -75px;
  font-size: 2rem;
  left: 93px;
  bottom: 0px ;
  cursor: pointer;
  height: 30px;

}
.uploadIconsvid1 {
  top: -75px;
  font-size: 2rem;
  left: 93px;
  bottom: 0px ;
  cursor: pointer;
  height: 30px;

}
.vidLabel{
display: flex;
justify-content: center;
color: #b8b7c1;
}
.uploadIconsvid2 {
  position: relative;
  top: 25px;
  font-size: 2rem;
  left: 209px;
  bottom: 0px;
  cursor: pointer;
  height: 25px;
}
.spanVid{
  padding: 0px 0px 0px 20px;
}