.Grn-Btnmanin.plan button {
    width: 236px;
    padding: 10px 15px;
    float: right;
}

.Grn-Btn.plan {
    width: 100%;
    margin-top: 20px;
    height: 45px;
    background: #107B38;
    color: #fff;
    border: #107B38;
    font-size: 17px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    border-radius: 5px;
}
.feature-include.d-flex input {
    width: 81%;
    border-radius: 5px;
    border: 1px solid #B8B7C1;
    height: 51px;
}
.feature-include.d-flex.addcourse .Add-row {
    padding: 11px 15px;
    height: 50px;
    border-radius: 4px;
}

.feature-include.d-flex .Add-row {
    background: #C7F7DA;
    border: 1px solid #107B38;
    margin-left: 10px;
    padding: 5px 14px;
    font-size: 28px;
    color: #107B38;
    margin-right: 10px;
    cursor: pointer;
    height: 42px;
}
.feature-include.d-flex.addcourse .del-row {
    padding: 8px 19px;
    height: 50px;
    border-radius: 4px;
}
.feature-include.d-flex .del-row {
    background: #F8D9D9;
    border: 1px solid #AB0A0A;
    padding: 5px 17px;
    font-size: 24px;
    color: #AB0A0A;
    cursor: pointer;
    height: 42px;
}
.labelname{
    width:100px
}
.css-1fdsijx-ValueContainer {
    height: 50px;
   
}
.modal-title.h4{
    margin-left: 10px;
}

.price-group {
    width: 95px;
    text-align: left;
}
.price-groupmain{
    display: flex;
}
.CateGorTab.subs table tr td:nth-child(6) {
    border-left: none;
}
.CateGorTab.subs table th {
    text-align: center;
    padding-right: 48px;
}
.CateGorTab.subs.jobs table th {
    text-align: center;
    padding-right: 0px;
}

.CateGorTab.jobcdt table tbody tr td {
text-align: center;
}
.view_button.job {
    border: solid 1px #b8b7c1;
    border-radius: 5px;
    font-weight: 500;
    color: #231f40;
    width: 20%;
    text-align: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
.subBod.modal-body {
    padding: 48px 101px;
}
.hidden-list.subLi{
    margin-left: -25px;
    margin-top: 4px;
}
.expLvlLabel{
    width: 180px;
}
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-list.subLi{
        margin-left: -25px;
        margin-top: 4px;
    }
    .expLvlLabel{
        width: 180px;
    }
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
        .hidden-list.subLi{
            margin-left: -25px;
            margin-top: 4px;
        }
        .expLvlLabel{
            width: 180px;
        }
        }
        @media (min-width: 1400px) and (max-width: 1920px) {
            .hidden-list.subLi{
                margin-left: -25px;
                margin-top: 4px;
            }
            }
      