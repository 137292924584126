.RejBtn {
  width: 100px;
  padding: 5px;
  background: #dcdae3;
  border: 1px solid #6f6b80;
}
.btnSrch {
  padding: 8px 16px;
}

.CorsELiSTabedu {
  background: #eeebf6;
  text-align: center;
}
.CorsELiSTabedu.pt-4.pb-4 {
  background: #eeebf6;
  padding: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.CateGorTab table {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border: 1px solid #bcbac4 !important;
}
.table.table-striped.table-bordered.table-hover tbody {
  border: 1px solid #bcbac4;
}

.subjectDiv{
  margin-top: 20px;
}
.subjectBox{
  width: 100%;
  background: #fff;
  border: 1px solid #b8b7c1;
  height: 50px;
  padding-left: 10px;
  border-radius: 5px; 
}




@media (min-width: 768px) and (max-width: 991px) {
  .Grn-Btn {
    font-size: 17px !important;
  }
  .WHt-Btn {
    font-size: 17px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .BtNSSearcH a {
    display: flex;
    font-size: 17px;
    justify-content: center;
    align-items: center;
  }
  .Grn-Btnmanin.lrnedit.eduEdit button {
    width: 100%;
    font-size: 14px;
    padding: 0px;
  }

  /* .Grn-Btn {
  display: flex;
  width: 100%;
} */
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .BtNSSearcH a {
    display: flex;
    font-size: 17px;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1400px) and (max-width: 1920px) {
  .Grn-Btnmanin.lrnedit.eduEdit button {
    width: 100%;
    font-size: 14px;
    padding: 0px;
  }
}

.ViewCon.educator {
  padding: 0;
}
.ViewCon.educator button {
  margin-top: 0;
}
.ViewCon.educator label {
  padding-top: 10px;
}

.view_button {
  border: solid 1px #b8b7c1;
  border-radius: 5px;
  font-weight: 500;
  color: #231f40;
}
.cvdetail {
  border: solid 1px #b8b7c1;
  border-radius: 5px;
  font-weight: 500;
  color: #231f40;
  text-decoration: none;
  padding: 1px 6px;
  background: #f0f0f0;
}
