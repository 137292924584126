.CateGorTab {
  background: #fff;
  padding: 20px;
}

.CateGorTab {
  background: #fff;
  padding: 25px;
  margin-top: -55px;
}

.CateGorTab table th {
  background: #231f40;
  color: #fff;
}
.Options.rATing{
    position: relative;
    display: inline-block;
    cursor: pointer;
width: auto;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: #fff;
}

.OnOfF {
  text-align: center;
  border: 1px solid #107b38;
  margin-right: 10px;
  padding-bottom: 5px;
  width: 90%;
  border-radius: 4px;
}

.table.table-striped.table-bordered.table-hover tbody {
  border-left: 1px solid #bcbac4;
}

.Options {
  text-align: center;
  border: 1px solid #107b38;
  padding-bottom: 4px;
  border-radius: 4px;
}

.Options i {
  font-size: 18px;
  color: #107b38;
}

.form-switch .form-check-input {
  width: 2.5em;
  height: 20px;
}

.form-check-input:checked {
  background-color: #107b38;
  border-color: #107b38;
}

.CateGorTab table tbody {
  border: transparent;
}

.CateGorTab table th {
  border: transparent;
}

.CateGorTab table tbody:first-child {
  border-left: 1px solid #bcbac4;
}

.CateGorTab table tbody {
  border-bottom: 1px solid #bcbac4;
}

.CateGorTab table {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-right: 1px solid #bcbac4 !important;
}

.CateGorTab table {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-right: 1px solid #bcbac4 !important;
}

.CateGorTab table td:nth-child(4) {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-left: transparent;
}

.CateGorTab table tbody tr td {
  height: 65px;
  padding-top: 18px !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.Grn-Btnmanin {
  margin-left: auto;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: transparent;
}

.Options {
  position: relative;
  display: inline-block;
  width: 60%;
  cursor: pointer;
}
.dots-icon {
  cursor: pointer;
}
.unOrdLi {
  margin-left: -35px;
}
.hidden-list {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 14px;
  z-index: 1;
  border: 1px solid #107b38;
  line-height: 30px;
  text-align: left;
}
.hidden-list.catLi {
  margin-left: -15px;
  margin-top: 5px;
}
.Options:hover .hidden-list {
  display: block;
}
.modal-header {
  background: #107b38;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 0;
}
.modal-dialog {
  max-width: 70% !important;
}
.modal-body {
  background: #eeebf6;
  padding: 48px 150px;
}
.modal.show .modal-dialog {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.WHt-Btn {
  background: #fff;
  border: 1px solid #107b38;
  color: #107b38;
  width: 100%;
  margin-left: 15px;
  margin-top: 20px;
  height: 45px;
  font-size: 21px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 4px;
}
.Half {
  width: 30% !important;
}
.Grn-Btn.Half {
  border-radius: 4px;
}
.WHt-Btn.Half {
  border-radius: 4px;
}
.form-label {
  color: #6f6b80;
}
.modal-title.h4 {
  text-align: center;
  float: unset;
  margin: auto;
  margin-left: 44%;
}
.modal-body .mb-3.input-group span {
  width: 225px;
  background: #fff;
  border: 1px solid #b8b7c1;
}
#basicborder {
  border-left: 1px solid #b8b7c1 !important;
  color: #231f40;
  font-size: 16px;
  height: 100%;
  margin-bottom: 0;
  padding: 10px;
}
.modal-body .mb-3.input-group span {
  color: #6f6b80;
}
.form-switch {
  padding-top: 3px;
  padding-bottom: 2px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Grn-Btn.Half.cat {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hidden-list.catLi {
    margin-left: -20px;
    margin-top: 4px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .Grn-Btn.Half.cat {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-body.catBod {
    padding: 48px 70px;
  }
  .hidden-list.catLi {
    margin-left: -33px;
    margin-top: 4px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-list.catLi {
    margin-left: -33px;
    margin-top: 4px;
  }
  .CateGorTab.ratingRe{
padding: 40px;
  }
}
