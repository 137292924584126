.BtNSSearcH.pur button {
  font-size: 15px;
}
.WhitBg {
  display: flex;
  background: #fff;
  padding: 10px 8px;
  border-radius: 5px;
  border: 1px solid #b8b7c1;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  align-items: center;
}
.WhitBg label {
  padding-left: 8px;
}
.WhitBg input {
  width: 14px;
  height: 17px;
}
.CateGorTab table tr td:nth-child(9) {
  border-right: 1px solid #bcbac4;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .Grn-Btn.pdf{
    height: 55px;
  }
  .purcHist{
    font-size: 22px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .Grn-Btn.pdf{
    height: 55px;
    margin-top: 2px;
  }
}
@media (min-width: 1400px) and (max-width: 1920px) {
  .Grn-Btn.pdf{
    height: 55px;
    margin-top: 2px;
  }
}