.DivWidth.faq {
  width: 554px !important;
}
.Optionsfaq {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: 1px solid #107B38;
  padding-bottom: 6px;
  border-radius: 4px;

}
.Optionsfaq  i {
  font-size: 18px;
  color: #107B38;
}
.Optionsfaq:hover .hidden-list {
  display: block;
}