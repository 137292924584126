.topBar {
  box-shadow: 0px 3px 2px #dcdcfe;
  padding: 7px;
}

.topBar {
  width: 100%;
  background: #fff !important;
}
.modalnot {
  background-color: #e9fff2;;
  width: 349px;
  height: 300px;
  z-index: 1;
  position: absolute;
  right: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  overflow-y: auto; /* Enable vertical scrollbar */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox (hide scrollbar) */
  -ms-overflow-style: none; /* For Internet Explorer and Edge (hide scrollbar) */
  scrollbar-width: none; /* For Chrome, Safari, and Opera (hide scrollbar) */
}
.modalnotfr::-webkit-scrollbar {
  width: 0.5em; /* For Chrome, Safari, and Opera */
}

.modalnotfr::-webkit-scrollbar-thumb {
  background-color: transparent; /* For Chrome, Safari, and Opera (hide scrollbar) */
}

.modalnotfr::-webkit-scrollbar-track {
  background-color: transparent; /* For Chrome, Safari, and Opera (hide scrollbar) */
}
.nottime {
 
  color: rgb(16, 123, 56);
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.nothead {
  text-align: center;
  background: #fff;
  height: 48px;
  padding-top: 12px;
  font-weight: 700;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-badge {
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 3px;
  right: -8px;
  font-size: 14px;
}
.notification-item.active {
  background-color: white !important; /* Background color when notification is clicked */
}
.notification-item{
  /* background-color: #e9fff2;; */
  cursor: pointer;
  /* height: 66px; 
  line-height: 34px; */
  position: relative;
  padding: 7px 14px;
}

.notification {
  background-color: #fff;
  border: 1px solid #085893;
  border-radius: 2px;
  border-radius: 50%;
  color: #107b38;
  display: inline-block;
  font-size: 20px;
  margin-top: 3px;
  padding: 0 8px 6px;
  position: relative;
  text-decoration: none;
}
.notification .badge {
  background-color: #107b38;
  border-radius: 40%;
  color: #fff;
  padding: 2px 6px;
  position: absolute;
  right: -4px;
  top: -8px;
}

.selected-message {
  background-color: white !important;
}

.notifyouter{
  position: relative;
}
.mainWrapper {
  width: 100%;
  display: flex;
}

.pull-left {
  float: left;
}

.icon-headernav {
  color: #212144;
  font-size: 25px;
  position: relative;
}

.button-header {
  cursor: pointer;
}

.notification-dot {
  height: 8px;
  width: 8px;
  background-color: #d3483d;
  border-radius: 50%;
  border: 1px solid white;
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 4px;
}
.admin-name{
  color: #fff;
  background: #107B38;
  padding: 7px 22px;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 6px;
  border-radius: 5px;
}
.user {
  height: 50px;
  width: 50px;
  background: aliceblue;
  text-align: center;
  padding: 1px 3px;
  border-radius: 50%;
  font-size: 23px;
}
.userDetail {
  margin-left: 11px;
  padding-top: 5px;
}
.userDetail h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800 !important;
}
.userDetail h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
}
.userDetails {
  margin-left: 10px;
}
.wrapperleft h1 {
  font-size: 26px;
  padding-top: 11px;
  text-transform: uppercase;
}
.icon-headernav {
  background: #107B38;
  color: #fff !important;
  border-radius: 59px;
  font-size: 30px;
  height: 50px;
  width: 50px;
  text-align: center;
}

/* .wrapperleft {
  padding-left: 25px;
} */

/* Media query start */
@media (min-width: 1440px) {
}

/* X-Large devices (large desktops, less than 1400px) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .wrapperleft h1 {
    font-size: 24px;
    padding-top: 11px;
    text-transform: uppercase;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .wrapperleft h1 {
    font-size: 22px;
    padding-top: 11px;
    text-transform: uppercase;
  }
}



@media (min-width: 0px) and (max-width: 320px) {
  .notification-dot {
    height: 8px;
    width: 8px;
    background-color: #d3483d;
    border-radius: 50%;
    border: 1px solid white;
    display: inline-block;
    position: relative;
    top: -9px;
    left: -12px;
  }
}

.userDetails {
  position: relative;
  width: 200px;
}

.Logout-btn {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #107B38;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}
/* .Logout-btn{
 
    display: flex;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #107B38;

    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    align-items: center;
    flex-direction: column;
    width: 101px;
    height: 106px;
} */

.userDetails:hover .Logout-btn {
  display: block;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .wrapperleft h1 {
    font-size: 22px;
    padding-top: 11px;
    text-transform: uppercase;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .wrapperleft h1 {
    font-size: 20px;
    padding-top: 11px;
    text-transform: uppercase;
  }
  
  .notification-dot {
    top: -7px;
    left: -10px;
  }
  
  .modalnot {
    display: none;
  }
  .nothead {
    font-size: 18px;
  }
  
  .notification-badge {
    width: 20px;
    height: 20px;
    font-size: 12px;
    top: 2px;
    right: -6px;
  }
  
  .nottime {
    bottom: 8px;
    right: 8px;
  }
  
  .notification-item {
    padding: 7px;
    font-size: 14px;
  }
  
  .selected-message {
    padding: 7px;
  }
  
  .Logout-btn {
    padding: 3px 7px;
  }
}