@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

/* Global css */
h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #231f40;
}
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #231f40;
}

a {
  text-decoration: none;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
}

.head-title h2 {
  font-family: "Patua One", cursive;
  margin-bottom: 0;
  font-size: 30px;
}

.commonBtn {
  background-color: #d3483d !important;
  border-radius: 4px;
  color: white !important;
  border: none;
}

.fntmons {
  font-family: "Montserrat", sans-serif;
}

.main-container {
  display: flex;
}
main {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #e9fbf3;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: #231f40 0% 0% no-repeat padding-box;
  color: white;
  height: 100vh;
  overflow-y: scroll !important;
}

::-webkit-scrollbar {
  display: none;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  transition: 0 !important;
  cursor: pointer;
  margin-bottom: 30px;
}

.logo {
  font-size: 18px;
  line-height: 0;
}

.bars {
  width: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
}
.hide {
  display: none;
}

.routes {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 10px 10px;
  border-right: 4px solid transparent;
  transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
}

.link:hover {
  background: #3d376a;
  color: white;
}

.active {
  background: #3d376a !important;
}

.active:hover {
  background: #3d376a !important;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  align-self: flex-end;
  margin-top: 3px !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.icon {
  font-size: 20px;
  line-height: 30px;
}

.anticon {
  font-size: 20px;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
  align-items: end;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 25px;
  /* border-bottom: #fff 0.5px solid; */
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent !important;
}

.element-with-scroll {
  overflow-y: scroll;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.element-with-scroll::-webkit-scrollbar {
  display: none !important;
}

select {
  cursor: pointer;
  padding-left: 8px;
}
.PageTit {
  background: transparent linear-gradient(180deg, #e4ffee 0%, #b1f5ca 100%);
  height: 170px;
  padding: 30px 25px;
}
.PageTit p a {
  color: #231f40;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.PageTit p i {
  color: #231f40;
}
* {
  scrollbar-width: thin;
  scrollbar-thumb-width: 2px;
  scrollbar-transition: all 0.3s ease;
  scrollbar-color: #fff white;

}
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}
::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners */
}
.Grn-Btnmanin button {
  font-size: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
 
  .CateGorTab {
    background: #fff;
    padding: 25px;
    margin-top: -55px;
    width: 100%;
    overflow: hidden;
      overflow-x: hidden;
    overflow-x: scroll;
  }
  .PageTit p {
    font-size: 13px;
  }
  .Grn-Btnmanin button {
    padding: 10px 11px;
  }
  .BtNSSearcH a {
    text-decoration: none;
    padding: 8px 17px;
  }
  .BtNSSearcH.send-mail a {
    padding: 12px 7px;
    font-size: 17px;
  }
  .PageTit {
    height: 226px;
  }
  .Grn-Btnmanin button {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 320px) {
  .addFs {
    font-size: 10px;
  }

  .textName {
    font-size: 18px !important;
  }
}

.tailSpin {
  z-index: 11111;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.tailSpin svg {
  height: 100px;
  width: 100px;
  left: 50%;
  top: 30%;
  position: fixed;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.notFoundDiv{
  text-align: center;
  font-size: 30px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* input[type="datetime-local"]::-webkit-datetime-edit-ampm-field {
  display: none !important;
}

input[type="datetime-local"]::-webkit-inner-spin-button {
  display: none !important;
} */

