.DivWidth {
    width: 600px !important;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .DivWidth {
      width: 400px !important;
    }

      .hidden-list.tstLi{
          margin-left: -25px;
          margin-top: 4px;
      }
      

  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .DivWidth {
      width: 260px !important;
    }
    .hidden-list.tstLi{
      margin-left: -25px;
      margin-top: 4px;
  }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .DivWidth {
      width: 260px !important;
    }
    .hidden-list.tstLi{
      margin-left: -25px;
      margin-top: 4px;
  }
  }
  @media (min-width: 1400px) and (max-width: 1920px){
.hidden-list.tstLi {
    margin-left: -25px;
    margin-top: 4px;
}
}