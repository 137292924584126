.LoGinPaGe {
  background: #107b38;
}
.MainDIV {
  padding-top: 5%;
  height: 100vh;
}
.col-lg-7.col-md-6.col-sm-4.Logn {
  padding: 0;
}
.col-lg-5.col-md-6.Logn {
  padding: 0;
}
.LoginForm {
  background: #fff;
  height: 100%;
  padding: 10px 50px;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
}
.FoRmLog {
  padding-top: 110px;
}
.LogO img {
  padding-top: 40px;
}
.FoRmLog h4 {
  font-weight: 700;
  margin-bottom: 20px;
}
.FoRmLog .form-control {
  height: 50px;
}
.FoRmLog input {
  border-right: none !important;
  border: 1px solid #b8b7c1;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: none !important;
  border: 1px solid #b8b7c1;
  font-size: 20px;
  color: #107b38;
}
.FoRmLog input {
  color: #231f40;
  font-size: 18px;
}
.col-12.text-left {
  text-align: left;
}
.col-12.text-left label {
  padding-left: 10px;
  color: #231f40;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.Grn-Btn {
  width: 100%;
  margin-top: 20px;
  height: 45px;
  background: #107b38;
  color: #fff;
  border: #107b38;
  font-size: 21px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 5px;
}
.logo-size {
  width: 280px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .FoRmLog {
    padding-top: 10px;
  }
  .LogO img {
    padding-top: 10px;
  }
  .FoRmLog h4 {
    font-size: 20px;
  }
  .FoRmLog .form-control {
    height: 45px;
  }
}
