.Count {
  text-align: center;
  height: 150px;
  color: #231f40;
  font-family: "Roboto", sans-serif;
  padding: 10px;
  padding-top: 40px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.Count.OranGE {
  background: #fceddd;
  border: 1px solid #ee8e28;
}

.Count.BluE {
  background: #defefc;
  border: 1px solid #23aca4;
}

.Count.PuRp {
  background: #f9defe;
  border: 1px solid #dc7cee;
}

.Count.YlW {
  background: #fefade;
  border: 1px solid #d4c55b;
}

.Count.GRn {
  background: #e2ffed;
  border: 1px solid #69d965;
}

.GrAphS {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  border: 1px solid #bcbac4;
  padding: 20px;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
}

.Count.ReD {
  background: #fedede;
  border: 1px solid #f24141;
}

.Count h3 {
  font-size: 30px;
}

.Count h6 {
  font-size: 20px;
}

canvas {
  width: 100% !important;
}
.defalut {
  position: absolute;
}
.dasHBoarDfilT {
  text-align: right;
  margin-right: auto;
}
.defalut .container {
  box-shadow: none !important;
}

/* media file */
@media (min-width: 768px) and (max-width: 991px) {
  .GrAphS {
    height: 370px !important;
  }
}
