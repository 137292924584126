.Grn-Btnmanin a {
  margin-left: auto;
  width: auto;
  text-decoration: none;
  padding: 7px 15px;
  float: right;
  text-align: center;
}

.upLoad {
  display: block;
  margin: 38px 78px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  width: 229px;
  height: 181px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}

.upLoadedit {
  display: flex;
  margin: 39px 7px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;

  width: 229px;
  height: 181px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}
.upLoadexperts {
  display: block;
  margin: 39px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  width: 229px;
  height: 181px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}
.fileUpload {
  display: none;
}
.uploadIcon {
  top: 187px;
  font-size: 2rem;
  left: 155px;
  bottom: 75px;
  cursor: pointer;
}

.TittlE {
  text-align: center;
}

.ExpertSBacKG {
  background: #eeebf6;
  padding: 35px;
}

.SHortsForm {
  padding: 0px 85px;
  padding-left: 0;
  font-family: "Roboto", sans-serif;
  margin-bottom: 12px;
}

.SHortsForm input {
  width: 100%;
  height: 47px;
  border-radius: 5px;
  border: 1px solid #b8b7c1;
  font-family: "Roboto", sans-serif;
  padding: 0 0 0 7px;
}

.hidden-list li a {
  color: #231f40;
  text-decoration: none;
}

textarea {
  width: 100%;
  border: 1px solid #b8b7c1;
  border-radius: 4px;
}
.table.table-striped.table-hover.shortsTable tbody {
  border: 0px solid #bcbac4;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .DivWidth.faq {
    width: 260px !important;
  }
}
.DivWidth.faq.titl {
  width: 325px !important;
}

.inputI {
  position: absolute;
  top: 182px;
  right: 15px;
}
.hidden-list.shrtLi {
  margin-left: -25px;
  margin-top: 4px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .upLoadedit {
    width: 202px;
    height: 180px;
  }
  .upLoadexperts {
    width: 206px;
  }
  .inputI {
    position: absolute;
    top: 182px;
    right: 15px;
  }
}
