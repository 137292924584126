.Grn {
  color: #107b38 !important;
}

.nail.blog.thumb {
  margin-bottom: 50px;
}

.editThumbnail {
  display: flex;
  padding: 15px;
  top: 60px;
  position: relative;
}

.editLabel {
  position: relative;
  top: 65px;
}

/* Add these styles to your CSS file or style section */
input[type="radio"]:disabled:checked {
  /* Set your preferred green color for the disabled checked radio button */
  filter: hue-rotate(90deg);
  /* Example: Adjust hue to change color */
  /* Add other desired styling properties */
}

input[type="radio" i]:disabled:checked {
  background-color: #107b38;
  color: #107b38;
  border-color: #107b38;
}

input[type="radio"]:checked + .radio-mark::after {
  color: #107b38;
}

.vidPlyr {
  display: flex;
  width: 45%;
  text-align: center;
  border: 1px solid #107b38;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.viewVideo {
  color: #107b38;
  font-size: 25px;
}

.upLoad.course {
  display: flex;
  flex-direction: row;
  height: 200px;
  margin: 0px;

  /* display: block; */
  margin: 38px 79px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 1px 3px;
  width: 228px;
  /* height: 192px; */
  position: relative;
  text-align: center;
  color: #b8b7c1;
}

.upLoad.course.add {
  display: flex;
  flex-direction: row;
  height: 200px;
  margin: 0px;

  /* display: block; */
  margin: 38px 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 0px 13px;
  width: 228px;
  /* height: 192px; */
  position: relative;
  text-align: center;
  color: #b8b7c1;
}

.progress-bar {
  background-color: #107b38;
  color: #fff;
}

.thumbnail-img.course {
  display: flex;
  width: 222px;
  height: 212px;
  padding-top: 0px;
  padding-bottom: 88px;
  margin-top: 0px;
  margin-bottom: 0px;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.CourSEl li {
  list-style: disc;
}

/* .css-1fdsijx-ValueContainer {
  height: 55px;
} */

/* .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;
} */
.collegeDrpDwn .css-3w2yfm-ValueContainer {
  height: 79px;
  overflow-y: scroll;
  scrollbar-color: green #fff;
}

.collegeDrpDwn {
  position: relative;
  box-sizing: border-box;
  height: 82px;
}

.collegeDrpDwn.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  height: 82px;
}

.collegeDrpDwn .css-1xc3v61-indicatorContainer {
  display: none;
}

.collegeDrpDwn .react-select__indicator {
  display: none;
}

.collegeDrpDwn .css-1fdsijx-ValueContainer {
  height: 79px;
}

.collegeDrpDwn .react-select__value-container {
  height: 79px;
  overflow-y: scroll;
  scrollbar-color: green #fff;
}

.collegeDrpDwn {
  width: 100%;
  background: #fff;
  border: 1px solid #b8b7c1;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.colChK {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.CorsELiSTab.pt-4.pb-4 {
  background: #eeebf6;
  padding: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #231f40 !important;
  border-color: #231f40;
  font-size: 18px;
  width: 185px;
  border-radius: 0;
  margin-right: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  border-radius: 4px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: transparent;
  background: #231f40;
  border-color: #231f40;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  border-radius: 0;
  font-size: 18px;
  border-radius: 4px;
}

.nav-tabs .nav-link {
  margin-bottom: 25px;
  width: 190px;
  margin-right: 25px;
  border-radius: 0;
  background: #eeebf6;
  color: #6f6b80;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  border: 1px solid #eeebf6;
  border-radius: 4px;
}

.nav-tabs {
  border-bottom: transparent;
}

.CorsELiSTab form select {
  width: 100%;
  background: #fff;
  border: 1px solid #b8b7c1;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.CorsELiSTab form input {
  width: 100%;
  background: #fff;
  border: 1px solid #b8b7c1;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.CorsELiSTab form textarea {
  width: 100%;
  background: #fff;
  border: 1px solid #b8b7c1;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.CorsELiSTab form .ck.ck-editor {
  width: 100%;
  background: #fff;

  margin-bottom: 20px;
}

.CorCountenT {
  background: #fff;
  margin-top: 25px;
}

.BluEBUTn {
  background: #231f40;
  color: #fff;
  text-decoration: none;
  padding: 11px 55px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 5px;
}

.DulBG {
  justify-content: center;
  background: #eeebf6;
  padding: 15px 15px;
  font-family: "Roboto", sans-serif;
}

.DuLLBg p {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  color: #231f40;
}

.DuLLBg p span {
  color: #6f6b80;
}

.AcBtN {
  margin-left: auto;
}

.AcBtN button {
  background: #dcdae3;
  border: 1px solid #b8b7c1;
  padding: 10px 10px;
  font-size: 15px;
  margin-right: 11px;
  border-radius: 5px;
  color: #6f6b80;
}

.AcBtN.Wh button {
  background: #fff;
}

.LeSSonSesion {
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
  border: 1px solid #b8b7c1;
  border-radius: 5px;
}

.LeSSonSesion p span {
  color: #6f6b80;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.LeSSonSesion p {
  color: #231f40;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.CorsELiSTab {
  background: #eeebf6;
  text-align: center;
  padding: 75px;
}

.CoConTenT .Grn-Btnmanin {
  margin-left: auto;
  margin-right: auto;
}

.CorsELiSTab form {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #6f6b80;
}

.CorsELiSTab input[type="checkbox"] {
  width: 20px;
  cursor: pointer;
}

.ThumBnail {
  margin-left: -35%;
}

.thumbnailfile {
  display: inline-block;
  padding-top: 84px;
}

.TApadig {
  padding-top: 12px;
  margin-left: 19px;
}

.NotCa {
  margin-top: -14px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.NotCa span {
  color: #b90719;
}

.CorsELiSTab.pt-4.pb-4 table tr td {
  border-bottom: 1px solid #b8b7c1;
  border-top: 1px solid #b8b7c1;
  text-align: left;
}

.CorsELiSTab.pt-4.pb-4 table tr {
  border-right: 1px solid #b8b7c1;
}

.CatCOlr {
  color: #6f6b80 !important;
}

.CorsELiSTab.pt-4.pb-4 table tr td {
  color: #231f40;
}

.PAdinGUl {
  padding-left: 0;
}

.accordion-button:not(.collapsed) {
  color: #6f6b80;
  background-color: #fff;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.AccCheck {
  margin-left: 20px;
}

/* Customize your radio button styles here */

.CustomRadioButton {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  /* Adjust spacing as needed */
}

.CustomRadioButton input[type="radio"] {
  display: none;
}

.AccCheck {
  position: relative;
}

.AccCheck::before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  border: 2px solid lightgray;
  width: 15px;
  height: 15px;
}

.AccCheck input[type="text"] {
  margin-left: 50px;
}

.CustomRadioButton input[type="radio"]:disabled:checked + .AccCheck::before {
  background-color: green;
  border: 2px solid lightgray;
  width: 15px;
  height: 15px;
}

.CustomRadioButton input[type="radio"]:checked + .AccCheck::before {
  background-color: green;
  border: 2px solid lightgray;
  width: 15px;
  height: 15px;
}

.accordion-item {
  margin-bottom: 10px;
}

.CateGorTab table tbody tr {
  background: #fff;
}

.assment-course.d-flex input {
  width: 80%;
  border-radius: 5px;
  border: 1px solid #b8b7c1;
}

.assment-course.d-flex .Add-row {
  background: #c7f7da;
  border: 1px solid #107b38;
  margin-left: 12px;
  padding: 10px 19px;
  font-size: 34px;
  color: #107b38;
  margin-right: 10px;
  cursor: pointer;
  height: 42px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assment-course.d-flex .del-row {
  background: #f8d9d9;
  border: 1px solid #ab0a0a;
  margin-left: 12px;
  padding: 10px 19px;
  font-size: 34px;
  color: #ab0a0a;
  margin-right: 10px;
  cursor: pointer;
  height: 42px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Grn-Btn.assesm {
  width: 100px;
}

.assment-course.d-flex.addcourse .Add-row {
  padding: 11px 15px;
  height: 50px;
  border-radius: 4px;
}

.assment-course.d-flex.addcourse .del-row {
  padding: 8px 19px;
  height: 50px;
  border-radius: 4px;
}

.Edit-tool {
  margin-left: 3%;
  font-size: 23px;
}

.Edit-tool i {
  background: #dcdae3;
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
}

.assment-course label {
  color: #6f6b80;
}

.CateGorTab table tr td:nth-child(9) {
  border-left: none;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.DuLLBg p {
  width: 580px;
}

.AcBtN.Wh button {
  padding: 10px 25px;
}

.AcBtN.asses {
  display: flex;
}

.OnOfFbtn {
  text-align: center;
  border: 1px solid #107b38;
  margin-right: -2px;
  margin-left: 254px;
  padding-bottom: -1px;
  width: 25%;
  border-radius: 4px;
}

.form-control {
  height: 50px;
}

.Grn-Btnmanin button {
  width: auto;
  padding: 10px 15px;
  float: right;
}

.css-wsp0cs-MultiValueGeneric {
  background: #d4fae3;
}

.css-9jq23d {
  background: #d4fae3;
}

.asses_btn {
  display: flex;
  margin-right: 0px;
}

.green-checkbox:checked {
  background-color: green;
}

.editthumb {
  flex-direction: row;
  display: block;
  margin: 9px 42px 79px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  padding: 0px 0px;
  width: 170px;
  height: 211px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}

.upLoadedImgCourse {
  display: block;
  /* margin: 29px 181px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0px;
  border: 1px solid #21214433;
  border-radius: 6px;
  width: 190px;
  height: 190px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}

.labelthumb {
  padding: 35px;
  margin-left: -33px;
  margin-top: 71px;
}

.course {
  /* right: 180px; */
  padding-top: 0px;
  position: relative;
  padding-bottom: 15px;
}

.preImageedit {
  height: 188px !important;
}

.imgthumbnailedit {
  display: flex;
  width: 187px;
  height: 209px;
  padding-top: 0px;
  padding-bottom: 21px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/* Pagination container */
.pagination-containers {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.green-pagination a {
  color: #107b38;
  text-decoration: underline;
}

/* Pagination item */
.pagination-containers li {
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}

/* Active page item */
.pagination-containers .active {
  font-weight: bold;
  color: #333;
  /* Color for the active page number */
}

/* Previous and Next buttons */
.pagination-containers .previous,
.pagination-containers .next {
  margin: 0 10px;
  cursor: pointer;
}

/* Disabled buttons */
.pagination-containers .disabled {
  color: #999;
  /* Color for disabled buttons */
  pointer-events: none;
}

/*01/04/24 */

.ThumBnails.newThumb {
  right: unset;
}

.ThumBnails.newThumb .thumbnail-container.crsCntn {
  padding: unset;
  margin: unset;
  display: flex;
  flex-direction: column;
  overflow: unset;
}

.ThumBnails.newThumb .thumbnail-container.crsCntn .blogadd.crsAd {
  display: flex;
  flex-direction: row;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  padding: 0px 0px;
  width: 509px;
  height: 322px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
  align-items: center;
  justify-content: center;
  margin: unset;
}

.ThumBnails.newThumb
  .thumbnail-container.crsCntn
  .iconUpload
  .custom-file-upload {
  display: inline-block;
  margin: unset;
  width: unset;
  height: unset;
}

.ThumBnails.newThumb
  .thumbnail-container.crsCntn
  .iconUpload
  .custom-file-upload
  .uploadthumb {
  display: flex;
  align-items: center;

  margin-left: 0;
  margin-top: -25px;
  width: unset;
  height: unset;
}

.ThumBnails.newThumb
  .thumbnail-container.crsCntn
  .iconUpload
  .custom-file-upload
  .iconupload.crsIcon {
  position: relative;
  top: 0;
  font-size: 2rem;
  left: 130px;
  cursor: pointer;
  height: 33px;
}

.ThumBnails.newThumb
  .thumbnail-container.crsCntn
  .blogadd.crsAd
  .thumbnail-preview {
  height: unset;
}

.ThumBnails.newThumb
  .thumbnail-container.crsCntn
  .blogadd.crsAd
  .thumbnail-preview
  .imgthumbnail {
  display: flex;
  width: 509px;
  height: 322px;
  padding: unset;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 6px;
}

/*end */
/* media */
@media (min-width: 1400px) and (max-width: 1920px) {
  .nav-tabs .nav-link {
    width: 230px;
  }

  .BluEBUTn {
    padding: 12px 35px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    width: 230px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Grn-Btn.adCrs {
    display: flex;
    width: 90%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .ThumBnail.adEduThumb {
    margin-left: -84px;
    margin-top: 0px;
  }

  .thumbnail-container.crsCntn {
    padding-left: 0px;
    padding-bottom: 0px;
    margin-left: 93px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

.ThumBnails {
  right: 138px;
  width: 100%;
  position: relative;
}

.BluEBUTn {
  padding: 12px 35px;
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .ThumBnails {
    right: 138px;
    width: 320px;
    position: relative;
  }
}

.Grn-Btn.adCrs {
  display: flex;
  width: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .Grn-Btnmanin a {
    padding: 11px 8px;
    font-size: 15px;
  }

  .ThumBnail.adEduThumb {
    margin-left: -84px;
    margin-top: 0px;
  }

  .Grn-Btn.adCrs {
    display: flex;
    width: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .modal-body {
    padding: 48px 20px;
  }

  .ThumBnails {
    right: 138px;
    width: 320px;
    position: relative;
  }

  .DuLLBg p {
    width: 500px;
  }

  .nav-tabs .nav-link {
    width: 155px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    width: 180px;
  }

  .BluEBUTn {
    padding: 11px 10px;
  }

  .ThumBnail {
    margin-left: -53%;
    margin-top: -25px;
  }

  .thumbnail-container.crsCntn {
    padding-left: 0px;
    padding-bottom: 0px;
    margin-left: 93px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  table tr td.d-flex {
    width: 133px !important;
  }

  .upLoad.course.add.addCrs {
    height: 200px;
    margin: -45px 0px 18px 95px;
    width: 208px;
    padding: 0px;
  }

  .eduPic {
    width: 25%;
  }

  .ThumBnail.adEduThumb {
    margin-left: 0px;
    margin-top: 0px;
  }

  .AcBtN.Wh button {
    font-size: 13px;
  }

  .AcBtN button {
    font-size: 13px;
  }

  .OnOfFbtn {
    margin-left: 190px;
    width: 35%;
  }

  .ThumBnails {
    right: -7px;
    width: 320px;
    position: relative;
  }

  .DuLLBg p {
    width: 300px;
  }

  .Half {
    width: 99% !important;
    margin-left: 0;
    margin-right: 5px;
  }

  .modal-body {
    padding: 48px 20px;
  }

  .Grn-Btnmanin a {
    padding: 11px 8px;
    font-size: 15px;
  }

  .BluEBUTn {
    padding: 11px 7px;
    font-size: 11px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    width: 180px;
    font-size: 13px;
  }

  .ThumBnail {
    margin-left: 10%;
    margin-top: -60px;
  }

  .hidden-list.crsLi {
    margin-left: -25px;
    margin-top: 4px;
  }

  .uploadthumb.crsThumb {
    margin-left: 17px;
    margin-top: 5px;
  }

  .iconupload.crsIcon {
    top: 2px;
  }

  .thumbnail-container.crsCntn {
    padding-left: 0px;
    padding-bottom: 0px;
    margin-left: 93px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .thumbnailfile {
    padding-top: 0px;
  }

  .blogadd.crsAd {
    margin: 9px 42px 5px;
  }

  .Grn-Btn.adCrs {
    display: flex;
    width: 140px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .searchBtNs input {
    font-size: 10px;
  }

  .BtNSSearcH select {
    font-size: 14px;
  }
}

.accordion-header button {
  width: 500px;
  height: 50px;
  padding: 0px 6px;
}

.accordion-header button input {
  border: none !important;
}

.Addasses input[type="checkbox"] {
  width: auto !important;
}

.Addasses .Checkboxans {
  margin-bottom: 17px;
  height: 40px;
  margin-left: 13px;
  padding: 10px;
}

.BtNSSearcH select {
  height: 50px;
}

.CorCountenT select {
  width: 100%;

  height: 45px;

  margin-bottom: 50px;
}

.CorsELiSTab input[type="radio"] {
  width: 10%;
}

input.radioWidth {
  width: 6% !important;
}

span.Grn-Btn {
  padding: 7px 25px;
  margin-left: 27px;
  cursor: pointer;
}

.CorCountenT select {
  width: 100%;
  height: 45px;
  margin-bottom: 50px;
}

.CorCountenT label {
  padding: 10px 60px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.Addasses input[type="radio"] {
  width: 20px;
}

h2.AccWidth.accordion-header button {
  width: 100%;
}

button.accordion-button p {
  font-size: 15px;
  width: 491px;
  margin-top: 22px;
  line-height: 18px;
}

h2.ViewCrsefun1.accordion-header button {
  width: 100%;
}

.payment-section {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.payment-section h5 {
  color: #333;
  margin-right: 10px;
}

.total-discount {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
