.ThumBnail {
  width: 320px;
}

.custom-file-upload {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 200px;
  height: 70px;
}

.uploadthumb {
  display: flex;
  align-items: center;
  padding: -57px;
  margin-left: 17px;
  margin-top: -75px;
  width: 600px;
  height: 29px;
}
.uploadthumbs.blogpad {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: -75px;
  width: 600px;
  height: 29px;
  position: relative;
  top: 0px;
}

.iconupload {
  position: relative;
  top: -75px;
  font-size: 2rem;
  left: 164px;
  bottom: 75px;
  cursor: pointer;
  height: 33px;
}
.iconuploads {
  position: relative;
  top: -170px;
  font-size: 2rem;
  left: 125px;
  bottom: 75px;
  cursor: pointer;
  height: 33px;
}
.iconuploads.blogicon {
  position: relative;
  top: -77px;
  font-size: 2rem;
  left: 175px;
  bottom: 55px;
  cursor: pointer;
  height: 35px;
}
.uploadthumbs {
  display: flex;
  align-items: center;
  margin-left: 19px;
  margin-top: 55px;
  width: 600px;
  height: 29px;
  position: relative;
  top: -135px;
}
.imgthumbnail {
  display: flex;
  width: 166px;
  height: 231px;
  padding-top: 0px;
  padding-bottom: 21px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.imgthumbnails {
  display: flex;
  width: 166px;
  height: 205px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 10px;
}
.thumbnail-container {
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: 100px;
  margin-top: 30px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;

  overflow: hidden;
}
.blogadd {
  display: flex;
  flex-direction: row;
  margin: 9px 42px 79px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #21214433;
  border-radius: 6px;
  padding: 0px 0px;
  width: 170px;
  height: 211px;
  position: relative;
  text-align: center;
  color: #b8b7c1;
}
.thumbnail-preview {
  height: 10px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-top: 0px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .thumbnail-container.adBlog {
    margin-left: 0px;
  }
  .blogadd {
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .uploadthumbs.blogpad.clkBlog {
    margin-top: 14px;
  }
  .iconuploads.blogicon.uplIcon {
    top: 10px;
    font-size: 2rem;
    left: 175px;
    cursor: pointer;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .thumbnail-container {
    padding-left: 0px;
    padding-bottom: 0px;
    margin-left: 10px;
    margin-top: 55px;
    margin-bottom: 0px;
  }
  .Grn-Btn.blogBtnGn {
    font-size: 18px;
  }
  .ThumBnail.blogcnt {
    margin-left: -40%;
  }
}
@media (min-width: 1201px) and (max-width: 1399px) {
  .thumbnail-container {
    padding-left: 0px;
    padding-bottom: 0px;
    margin-left: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .ThumBnail.blogcnt {
    margin-left: -10%;
  }
}
@media (min-width: 1400px) and (max-width: 1920px) {
  .ThumBnail.blogcnt {
    margin-left: -30%;
  }
}
